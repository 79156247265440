
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import mAxiosApi from "@/api";
import Users from "@/views/still/admin/users.vue"
import Histo from "@/views/still/admin/histo.vue"
import ResultTest from "@/views/still/admin/resultTest.vue"
import Reserv from "@/views/still/admin/reserv.vue"
import { ElNotification } from 'element-plus'

import * as moment from "moment";
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    Users,
    Histo,
    ResultTest,
    Reserv,
  },
 
  setup() {

    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      loaderEnabled: true,
      lProducteurs: [] as any,
      lUsers: [] as any,
      lRoles: [] as any,
      drawer: false,
      histo: false,
      drawer_morale: false,
      count: 0,
      paneBord : 0,
      zUser: {} as any,
      reloadlst : 1,
      lHisto : [] as any, 
      reloadHisto : 1,
      currentUser : 0,
    });

    const tableHeader = ref([
      { name: "Date", key: "hse_d_creation", sortable: false,},
      { name: "Recherche", key: "hse_c_search", sortable: false,},
      { name: "", key: "hse_n_seq", sortable: false,},
    ]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang +" HH:mm:ss");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const modalUser= reactive({
      uti_n_seq: 0,
      uti_c_service: "",
      uti_c_phone: '',
      uti_c_mail: '',
      uti_knum_role: '',
      uti_c_prenom: '',
      uti_c_nom : '',
      uti_c_mess_instant : '',
      uti_c_status: '',
      uti_x_stillnetwork : 0,
      uti_x_adm : 0,
      uti_x_bloque: 0,
      uti_c_societe: "",
      uti_knum_user_still: 0 as any,
    });

    onMounted(async () => {
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Administration de l'application", []);

      let myListLiv = await getAxios("/getSVUsers/still");
      state.lProducteurs = myListLiv.results;
      
      myListLiv = await getAxios("/getSVRoles");
      state.lRoles = myListLiv.results;

      const myListUsers = await getAxios("/getSVUsers");
      state.lUsers = myListUsers.results;

      state.lUsers.forEach(element => {
        if (element.producteur) {
          element.producteur = element.producteur.split(",").map(function(e){return parseInt(e,10)});
        }
      });
      
      state.count =state.count + 1;
      state.paneBord=1;
    });

    const newUser = () => {
      modalUser.uti_n_seq = 0;
      modalUser.uti_c_service = "",
      modalUser.uti_c_phone = '',
      modalUser.uti_c_mail = '',
      modalUser.uti_knum_role = '',
      modalUser.uti_c_prenom = '',
      modalUser.uti_c_nom = '',
      modalUser.uti_c_mess_instant = '',
      modalUser.uti_c_status = '',
      modalUser.uti_x_stillnetwork = 0,
      modalUser.uti_x_adm = 0,
      modalUser.uti_x_bloque = 0,
      modalUser.uti_knum_user_still = null,
      modalUser.uti_c_societe= "",
      state.drawer = true;
    }

    const editUser = (d) => {
      modalUser.uti_n_seq = d.uti_n_seq;
      modalUser.uti_c_service = d.uti_c_service,
      modalUser.uti_c_phone = d.uti_c_phone,
      modalUser.uti_c_mail = d.uti_c_mail,
      modalUser.uti_knum_role = d.uti_knum_role,
      modalUser.uti_c_prenom = d.uti_c_prenom,
      modalUser.uti_c_nom = d.uti_c_nom,
      modalUser.uti_c_mess_instant = d.uti_c_mess_instant,
      modalUser.uti_c_status = d.uti_c_status,
      modalUser.uti_x_stillnetwork = d.uti_x_stillnetwork,
      modalUser.uti_x_adm = d.uti_x_adm,
      modalUser.uti_x_bloque = d.uti_x_bloque,
      modalUser.uti_c_societe= d.uti_c_societe,
      modalUser.uti_knum_user_still = d.uti_knum_user_still,
      state.drawer = true;
    }

    const getLengthProd = computed(() => {
      if(!modalUser.uti_knum_user_still) return 0;
     return modalUser.uti_knum_user_still;
    });


    const affHisto = async (data) => {
      /*
      let myListHisto = await getAxios("/getSVUserHisto/" + data.uti_n_seq);
      state.lHisto = myListHisto.results;
      state.reloadHisto += 1;
      */
      state.currentUser = data.uti_n_seq;
      state.histo = true;
    }

    const addEditUser = () => {
      if (!modalUser.uti_c_prenom) {
          ElNotification({ title: t('Erreur'), message: t('Le champ Prénom est obligatoire'), type: 'error', }); 
          return false;
        }
        if (!modalUser.uti_c_nom) {
          ElNotification({ title: t('Erreur'), message: t('Le champ Nom est obligatoire'), type: 'error', }); 
          return false;
        }
        if (!modalUser.uti_c_mail) {
          ElNotification({ title: t('Erreur'), message: t('Le champ Email est obligatoire'), type: 'error', }); 
          return false;
        }

        mAxiosApi
          .post("/insertEditUser", modalUser)
          .then( async() => {
            const mNotif = modalUser.uti_n_seq > 0 ? t("Utilisateur modifié avec succès !") : t("Utilisateur créé avec succès !");
            ElNotification({ title: t('Succès'), message: mNotif, type: 'success', })
            state.count = state.count + 1
            state.drawer = false;
          })

    } 

    return {
      state,
      addEditUser,
      modalUser,
      newUser,
      editUser,    
      getLengthProd,  
      affHisto,
      tableHeader,
      formatDate,
      formatDateAgo
    }
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

