<template>
  <transition name="fade">
      <el-row :gutter="10" class="mt-10" style="width: 100%">
        <el-col :span="24">

          <div class="card" v-if="!state.loaderEnabled" style="width: 100%;">
            <div class="card-header border-0 pt-6">
              <div class="card-title" style="width:100%;">
                <h2>{{$t("Liste des utilisateurs")}}</h2>
              </div>
              <SearchAndFilter
                :listInitial="state.initialMyList"
                :filters="tableFilters"
                @returnSearch="state.myList = $event"
                @returnLoadingDatatable="state.loadingDatatable = $event"
                ExcelFilename="StillOnStockUtilisateur"
                :ExcelFormat="ExcelFormat"
              ></SearchAndFilter>
            </div>

            <div class="card-body pt-0">
              <div
                v-if="state.myList.length == 0"
                class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
              >
                <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                  <inline-svg src="media/icons/duotune/general/gen048.svg" />
                </span>
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-dark">{{$t("Aucune ligne à afficher")}}</h4>
                  <span>{{$t("Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.")}}</span>
                </div>
              </div>
              <Datatable
                v-if="state.myList && state.myList.length > 0"
                :table-data="state.myList"
                :table-header="tableHeader"
                :enable-items-per-page-dropdown="true"
                :rows-per-page="50"
                :empty-table-text="$t('Aucune ligne à afficher.')"
                :key="state.loadingDatatable"
                class="table-small-td"
              >
                <template v-slot:cell-uti_c_prenom="{ row: data }">{{ data.uti_c_prenom }} {{ data.uti_c_nom }}</template>
                <template v-slot:cell-uti_c_mail="{ row: data }">
                  <div class="fs-7 text-gray-500">
                    <div>{{ data.uti_c_phone }}</div>
                    <div class="text-blue">{{ data.uti_c_mail }}</div>
                  </div>
                </template>

                <template v-slot:cell-ins_bonne_reponse="{ row: data }">
                  <div class="fs-7 text-gray-700">
                    <div><span class="fs-4 text-gray-900"><b>{{ data.ins_bonne_reponse }}</b></span> / {{ data.ins_tot_question }} </div>
                    <br>
                    <span class="text-gray-600">Soit {{Math.round(100 * data.ins_bonne_reponse / data.ins_tot_question) }}% de taux de réussite</span>
                  </div>
                </template>

                <template v-slot:cell-ins_perte_focus="{ row: data }">
                  <div class="fs-7 text-gray-500">
                    <div><b>{{ data.ins_perte_focus }}</b> </div>
                  </div>
                </template>
                

                <template v-slot:cell-uti_d_import="{ row: data }">{{ formatDate(data.uti_d_import) }}</template>
                <template v-slot:cell-uti_c_societe="{ row: data }">
                  {{ data.uti_c_societe }}<br />
                  <span class=" text-gray-400" v-if="data.still_prenom">{{$t("AM Still':")}} {{ data.still_prenom }}</span>
                </template>
                <template v-slot:cell-uti_c_status="{ row: data }">
                  <span class="badge badge-success" v-if="data.uti_c_status=='Compte actif'">{{ data.uti_c_status }}</span>
                  <span class="badge badge-danger" v-else>{{ data.uti_c_status }}</span>
                </template>
                <template v-slot:cell-uti_n_seq="{ row: data }">
                  <span class="badge badge-success me-2" v-if="data.uti_x_adm==1">{{$t("Adminstrateur")}}</span>
                  <span class="badge badge-danger me-2" v-if="data.uti_x_bloque==1">{{$t("Compte désactivé")}}</span>
                </template>
                <template v-slot:cell-uti_n_seq_action="{ row: data }">
                  <div style='width:495px;'>
                    <a class="btn btn-sm btn-custom-white mx-1" @click='displayUser(data)'>
                      <span class="svg-icon svg-icon-3 svg-icon-primary">
                        <inline-svg src="media/icons/duotune/art/art005.svg"/> </span>
                        {{$t("Modifier")}}
                    </a>

                    <el-popconfirm  :confirm-button-text="$t('Oui, réinitialiser le mot de passe')"  :cancel-button-text="$t('Non')"  :title="$t('Réinitialiser le mot de passe et renvoyer un mail à l\'utilisateur ?')"  @confirm="resetMdp(data)"  >
                      <template #reference>  
                        <a class="btn btn-sm btn-custom-white mx-1">
                          <span class="svg-icon svg-icon-2 svg-icon-danger">
                            <inline-svg src="media/icons/duotune/general/gen047.svg"/> </span>
                            {{$t("Mot de passe")}}
                        </a>
                      </template>
                    </el-popconfirm>

                    <a class="btn btn-sm btn-custom-white mx-1" @click='displayUserHisto(data)'>
                      <span class="svg-icon svg-icon-3 svg-icon-info">
                        <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                        {{$t("Réponses au test")}}
                    </a>

                  </div>
                </template>
              </Datatable>
            </div>
          </div>

        </el-col>
      </el-row>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchAndFilter from "@/views/still/search_and_filters_old.vue";

import * as moment from "moment";

import mAxiosApi from "@/api";
import { ElNotification } from 'element-plus'
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
  },
  
  emits: ["displayUser", "displayUserHisto"],

  setup( props, { emit }) {
    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zUser: {},
    });
    const tableHeader = ref([
      { name: "Prénom Nom", key: "uti_c_prenom", sortable: false,},
      { name: "Coordonnées", key: "uti_c_mail", sortable: false,},
      { name: "Date Test", key: "uti_d_import", sortable: false,},

      { name: "Score test", key: "ins_bonne_reponse", sortable: true,},
      { name: "Perte de focus", key: "ins_perte_focus", sortable: true,},

      { name: "", key: "uti_n_seq", sortable: false,},
      { name: "",  key: "uti_n_seq_action", sortable: false,},
    ]);

    const ExcelFormat = ref({
      "Prénom": "uti_c_prenom",
      "Nom": "uti_c_nom",
      "Tel": "uti_c_phone",
      "Mail": "uti_c_mail",
      "Creation": "uti_d_import",
      "Score test": "ins_bonne_reponse",
      "Perte de focus": "ins_perte_focus",
    });

    const tableFilters = ref([
      { name: "AM", key: "still_prenom", label: "still_prenom", },
      { name: "Société", key: "uti_c_societe", label: "uti_c_societe", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang + " HH:mm:ss");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const affDetail = (d) => {
      state.zUser = d;
    };

    const resetMdp = (d) => {
      mAxiosApi
        .get("/reinitPassword/" + d.uti_n_seq)
        .then((d: any) => {
          ElNotification({ title: t('Succès'), message: t('Mot de passe réinitialisé avec succès !'), type: 'success', })
        })
    }

    onMounted(async () => {

      let myListLiv = {} as any;

      myListLiv = await getAxios("/getSVUsers");
      state.myList = myListLiv.results;

      state.myList.forEach(element => {
        if (element.uti_x_adm == 1) element.uti_x_adm = true;
        if (element.uti_x_stillnetwork == 1) element.uti_x_stillnetwork = true;
        if (element.uti_x_bloque == 1) element.uti_x_bloque = true;

        if (element.producteur) {
          element.producteur = element.producteur.split(",");  //.map(function(e){return parseInt(e,10)})
        }
      });


      state.initialMyList = myListLiv.results;
      state.zUser = state.myList[0];
      state.loaderEnabled = false;

    });

    const displayUser = (d) => {
      emit("displayUser", d);
    }
    const displayUserHisto = (d) => {
      emit("displayUserHisto", d);
    }
    


    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      affDetail,
      displayUser,
      resetMdp,
      displayUserHisto,
      ExcelFormat
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
