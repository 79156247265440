<template ref="fullscreenElement">
    <transition name="fade">
        <el-row :gutter="10" v-if="!state.loaderEnabled" :class="state.classTest" style="width: 100%;">
          <el-col :span="16">
  
            <div class="card"  style="width: 100%;">
              <div class="card-header border-0 pt-6">
                <div class="card-title" style="width:100%;">
                  <h2>Test Technique : {{ state.myTest.tst_libelle }}</h2>
                </div>
              </div>
  
              <div class="card-body pt-0">
                <hr>
    
                <template v-for="item in state.quiz" :key="item.id">
                  <div>
                    <h3 class="mt-10 text-primary text-uppercase"><img :src="state.categ.filter(ref => ref.id == item.categ)[0].icon" style="max-width: 30px; max-height: 20px" class="mx-3" /><b>{{ state.categ.filter(ref => ref.id == item.categ)[0].label }}</b></h3>
                    <h4 class="mt-10 mb-5 text-black unselectable">{{ item.question }}</h4>
                    
  
                    <pre v-if ="item.code" class="language-js">
                      <code class="language-js unselectable">{{ item.code }}</code>
                    </pre>
  
                    <CodeHighlighter lang="js" :fieldHeight="0" class="d-none unselectable"></CodeHighlighter>
                    
                    <h4 class="mt-10 mb-5 text-black unselectable">Votre réponse :</h4>
  
  
                    <template v-for="rep in item.reponses" :key="rep.label">
                      <div class="form-check form-check-custom form-check-solid mb-2 chcbox p-2" style="align-items: center;">
                        
                        <input class="form-check-input mx-2 bg-success" type="checkbox" v-model="rep.xValid" disabled />

                        <input class="form-check-input" type="checkbox" v-model="rep.chxUser" disabled />
                        <div class="ms-3 form-text text-dark fs-4 m-0 unselectable">{{ rep.label }}</div>
                      </div>
                    </template>
  
                    
  
                    <br>
                    <div style="float:right" class="mt-10 mb-10 d-none">
                      <div class='btn btn-lg btn-secondary mx-2' v-if="state.quiz.findIndex(ind => ind.id === item.id) > 0" @click='prevQuestion(item.id)'>⬅️ Question précédente</div>
                      <div class='btn btn-lg btn-primary' v-if="state.quiz.findIndex(ind => ind.id === item.id) < state.quiz.length-1" @click='nextQuestion(item.id)'>Question suivante ➡️</div>
                      <div class='btn btn-lg btn-primary' v-else @click='final(item.id)'>Finaliser le test 🎯</div>
                    </div>
                  </div>
              </template>
  
              </div>
            </div>
  
          </el-col>
          <el-col :span="8">
            
  
            <div class="card" v-if="!state.loaderEnabled" style="width: 100%;">
              <div class="card-header border-0 pt-6">
                <div class="card-title" style="width:100%;">
                  <h2>{{$t("Résultat")}}  - <i>{{ state.total_reponse }} réponse{{ state.total_reponse > 1 ? "s" : "" }} donnée{{ state.total_reponse > 1 ? "s" : "" }} sur {{ state.quiz.length }} questions</i></h2>
                </div>
              </div>
  
              <div class="card-body pt-0">
                <hr>
  
                <div class="demo-collapse">
                  <el-collapse v-model="state.activeCateg">
                    <el-collapse-item :title="item.label" :name="state.activeCateg" v-for="item in state.categ" :key="item.id">
                      
                     <el-progress :text-inside="true" :stroke-width="20" :percentage="item.pourc" />
  
                    </el-collapse-item>
                  </el-collapse>
                </div>
             
                
              </div>
            </div>
  
          </el-col>
        </el-row>
    </transition>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, reactive, onUnmounted } from "vue";
  
  import mAxiosApi from "@/api";
  import { useI18n } from "vue-i18n";
  import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";

  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { ElMessageBox } from 'element-plus'
  
  export default defineComponent({
    name: "Overview",
    props: {
      idTest: Number,    
      idUser: Number,
    },
    components: {
      CodeHighlighter
    },
    
    emits: ["retour"],
  
    setup( props, { emit }) {
        
      const { t } = useI18n()
      const state = reactive({
        
        quiz : [] as any,
        categ : [] as any,
        activeCateg:0,
        currentQuestion : 0,
        loaderEnabled: true,
        myList: [] as any,
        loadingDatatable: 0,
        total_reponse : 0,
        myTest : [] as any,
        classTest : '',
        message: '',
      });
  

  
      onMounted(async () => {
          
        const myTest = await getAxios("/getTest/" + props.idTest);
        state.myTest = myTest.results;
   
        const myCategs = await getAxios("/getCategScore/" + props.idTest + "/" + props.idUser);
        state.categ = myCategs.results;
  
        const getQuiz = await getAxios("/getQuiz/" + props.idTest + "/" + props.idUser);
        state.quiz = getQuiz.results;
  
        
        state.activeCateg = state.quiz[0].categ;
        state.currentQuestion = state.quiz[0].id;

        testValid();

        state.loaderEnabled = false;
  
      });
  
      onUnmounted(() => {
        //        
      });
  
  
      const nextQuestion = (currentId) => {
        state.quiz.filter(ref => ref.id == currentId)[0].display = 0;
  
        const currentIndex = state.quiz.findIndex(item => item.id === currentId);
        if (currentIndex !== -1 && currentIndex + 1 < state.quiz.length) {
          state.quiz[currentIndex + 1].display = 1;
          state.currentQuestion = state.quiz[currentIndex + 1].id;
          state.activeCateg = state.quiz[currentIndex + 1].categ;
        }
        testValid();
      }
  
      const final = (currentId) => {
  
        ElMessageBox.confirm('Souhaitez-vous valider et soumettre vos réponses au test ? Il ne sera plus possible de revenir sur le test.','Confirmation',
            { confirmButtonText: 'Soumettre le test', cancelButtonText: 'Annuler', type: 'warning'  }
        )
        .then( async () => {
          nextQuestion(currentId);
          await getAxios("/setFinal/" + props.idTest); 
          Swal.fire({title: "Merci à vous !",  text:'Nous allons analyser vos réponses et revenons vers vous dans quelques minutes. Veuillez patientez svp.', icon: "success", buttonsStyling: false, 
          confirmButtonText: t("Retour à l'accueil"),customClass: { confirmButton: "btn fw-bold btn-light-primary", }}).then(function () {emit("retour", 1);})
        })
  
      }
  
      const setReponse = async (idQuest, idReponse, reponse) => {
        await getAxios("/setReponse/" + idQuest + '/' + idReponse + '/' + (reponse ? 1:0));
      }
      
  
  
      const prevQuestion = (currentId) => {
        state.quiz.filter(ref => ref.id == currentId)[0].display = 0;
  
        const currentIndex = state.quiz.findIndex(item => item.id === currentId);
        if (currentIndex !== -1) {
          state.quiz[currentIndex - 1].display = 1;
          state.currentQuestion = state.quiz[currentIndex - 1].id;
          state.activeCateg = state.quiz[currentIndex - 1].categ;
        }
        testValid();
      }
  
      const testValid = () => {
        state.total_reponse = 0;
        state.quiz.forEach((val) => {
          val.valid = 0;
          val.reponses.forEach((rep) => {        
            if (rep.chxUser) {
              if (val.valid == 0) state.total_reponse ++;
              val.valid = 1;
            }
          })
        })
      }    
  
      const clickQuestion= (id) => {
        
        state.quiz.forEach((val) => {
          val.display = 0;
        })
  
        state.quiz.filter(ref => ref.id == id)[0].display = 1;
        state.currentQuestion = id;
        testValid();
      }    
  
  
      return {
        state,
        nextQuestion,
        prevQuestion,
        testValid,
        clickQuestion,
        final,
        setReponse
      };
    },
  });
  
  export async function getAxios(request: string): Promise<any> {
    const response = await mAxiosApi.get(request);
    return response.data;
  }
  </script>
  