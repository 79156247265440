
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchAndFilter from "@/views/still/search_and_filters_old.vue";

import * as moment from "moment";

import mAxiosApi from "@/api";
import { ElNotification } from 'element-plus'
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
  },
  
  emits: ["displayUser", "displayUserHisto"],

  setup( props, { emit }) {
    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zUser: {},
    });
    const tableHeader = ref([
      { name: "Prénom Nom", key: "uti_c_prenom", sortable: false,},
      { name: "Coordonnées", key: "uti_c_mail", sortable: false,},
      { name: "Date Test", key: "uti_d_import", sortable: false,},

      { name: "Score test", key: "ins_bonne_reponse", sortable: true,},
      { name: "Perte de focus", key: "ins_perte_focus", sortable: true,},

      { name: "", key: "uti_n_seq", sortable: false,},
      { name: "",  key: "uti_n_seq_action", sortable: false,},
    ]);

    const ExcelFormat = ref({
      "Prénom": "uti_c_prenom",
      "Nom": "uti_c_nom",
      "Tel": "uti_c_phone",
      "Mail": "uti_c_mail",
      "Creation": "uti_d_import",
      "Score test": "ins_bonne_reponse",
      "Perte de focus": "ins_perte_focus",
    });

    const tableFilters = ref([
      { name: "AM", key: "still_prenom", label: "still_prenom", },
      { name: "Société", key: "uti_c_societe", label: "uti_c_societe", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang + " HH:mm:ss");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const affDetail = (d) => {
      state.zUser = d;
    };

    const resetMdp = (d) => {
      mAxiosApi
        .get("/reinitPassword/" + d.uti_n_seq)
        .then((d: any) => {
          ElNotification({ title: t('Succès'), message: t('Mot de passe réinitialisé avec succès !'), type: 'success', })
        })
    }

    onMounted(async () => {

      let myListLiv = {} as any;

      myListLiv = await getAxios("/getSVUsers");
      state.myList = myListLiv.results;

      state.myList.forEach(element => {
        if (element.uti_x_adm == 1) element.uti_x_adm = true;
        if (element.uti_x_stillnetwork == 1) element.uti_x_stillnetwork = true;
        if (element.uti_x_bloque == 1) element.uti_x_bloque = true;

        if (element.producteur) {
          element.producteur = element.producteur.split(",");  //.map(function(e){return parseInt(e,10)})
        }
      });


      state.initialMyList = myListLiv.results;
      state.zUser = state.myList[0];
      state.loaderEnabled = false;

    });

    const displayUser = (d) => {
      emit("displayUser", d);
    }
    const displayUserHisto = (d) => {
      emit("displayUserHisto", d);
    }
    


    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      affDetail,
      displayUser,
      resetMdp,
      displayUserHisto,
      ExcelFormat
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
