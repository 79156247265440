
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchAndFilter from "@/views/still/search_and_filters_old.vue";
import { ElMessageBox } from 'element-plus'

import { ElNotification } from 'element-plus'

import * as moment from "moment";

import mAxiosApi from "@/api";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
   Datatable,
   SearchAndFilter,
  },

  setup() {
    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      myListLiv: [],
      myQuiz: [] as any,
      categ: [] as any,
      lSN: [],
      mObj: {},
      zUser: {},
      lientest: 0,
      addQUestion : 0,
    });
    const tableHeader = ref([
      { name: "Test", key: "tst_libelle", sortable: false,},
      { name: "", key: "tst_seq", sortable: false,},
    ]);

    const tableFilters = ref([]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang + " HH:mm:ss");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const gestTst = async (idTst) => {

      state.lientest = idTst;

      let reponse = await getAxios("/getQuiz/" + idTst);
      state.myQuiz = reponse.results;

      const myCategs = await getAxios("/getCateg/" + idTst);
      state.categ = myCategs.results;

      state.drawer = true;
    }

    const ExcelFormat = ref({});

    onMounted(async () => { 

      loadTests();
      state.loaderEnabled = false;

    });


    const loadTests= async ()=>{
      let reponse = await getAxios("/getTests");
      state.myList = reponse.results;
      state.initialMyList = reponse.results;
    }

    const handleClose = (done: () => void) => {
      ElMessageBox.confirm('Are you sure you want to close this?')
        .then(() => {
          done()
        })
        .catch(() => {
          // catch error
        })
    }


    
    const insertReponse = (idQuest) => {
      idQuest.push({
        "id": "-1",
        "label": "",
        "chxUser": false,
        "xValid": false
      })
    }


    const insertQuestion = () => {
      state.addQUestion-- ;
      state.myQuiz.push({
        "id": state.addQUestion,
        "categ": "0",
        "display": 0,
        "valid": 0,
        "question": "",
        "code": "",
        "langcode": "",
        "lientest": state.lientest,
        "reponses": [
          {
            "id": "-1",
            "label": "",
            "chxUser": false,
            "xValid": false
          }
        ]
      })
    }
    

    const updateTest = () => {
      const form: any = state.myQuiz;
      mAxiosApi
        .post("setUpdateTest", { form })
        .then(async (data) => {

          ElNotification({
            title: 'Succès',
            message: 'Modifications réalisée avec succès !',
            type: 'success',
          })
          await loadTests();
          state.drawer = false
          
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      ExcelFormat,
      gestTst,
      handleClose,
      insertReponse,
      insertQuestion,
      updateTest

    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
