<template>
  <transition name="fade">
    <div>
      <el-row :gutter="10" class="mt-10" style="width: 100%">
        <el-col :span="24">

          <div class="card" v-if="!state.loaderEnabled" style="width: 100%;">
            <div class="card-header border-0 pt-6">
              <div class="card-title" style="width:100%;">
                <h2>{{$t("Gestion des tests")}}</h2>
              </div>
              <SearchAndFilter
                :listInitial="state.initialMyList"
                :filters="tableFilters"
                @returnSearch="state.myList = $event"
                @returnLoadingDatatable="state.loadingDatatable = $event"
                ExcelFilename="StillOnStockHistorique"
                :ExcelFormat="ExcelFormat"
                ></SearchAndFilter>
            </div>
            <div class="card-body pt-0">
              <div
                v-if="state.myList.length == 0"
                class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
              >
                <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                  <inline-svg src="media/icons/duotune/general/gen048.svg" />
                </span>
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-dark">{{$t("Aucune ligne à afficher")}}</h4>
                  <span>{{$t("Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.")}}</span>
                </div>
              </div>
             <Datatable
                      v-if="state.myList && state.myList.length > 0"
                      :table-data="state.myList"
                      :table-header="tableHeader"
                      :enable-items-per-page-dropdown="true"
                      :key="state.loadingDatatable"
                      :empty-table-text="$t('Aucune recherche effectuée')"
                      class="table-very-small-td"
                    >
                    <template v-slot:cell-tst_libelle="{ row: data }"> 
                        {{ data.tst_libelle }}
                    </template>
                    <template v-slot:cell-tst_seq="{ row: data }"> 
                      <a @click="gestTst(data.tst_seq)" class="btn btn-primary">
                        Gérer
                      </a>
                    </template>              
              </Datatable>

            </div>
          </div>

        </el-col>
      </el-row>

      <el-drawer v-model="state.drawer" title="Gestion du test" size="90%" :before-close="handleClose">
       
        <div style="overflow: auto">
          <div style="position: fixed; right: 74px; top: -3px" class="mt-5 ms-5">
            <el-button class="btn btn-secondary" @click="state.drawer = false">{{$t("Annuler")}}</el-button>
            <el-button class="btn btn-primary" @click="updateTest">Enregistrer les modifications</el-button>
          </div>

          <el-collapse class="p-10">

             
              <el-collapse-item :title="`Q${index + 1} - ${item.categ > 0 ? state.categ.filter(ref => ref.id == item.categ)[0].label : 'Sans catégorie'} - ${item.question ? item.question : 'Veuillez saisir la question'}`" :name="item.id" v-for="(item, index) in state.myQuiz" :key="item.id">

                  <el-row :gutter="10" class="mt-1" style="width: 100%">
                    <el-col :span="2"><b>Catégorie :</b>  </el-col>
                    <el-col :span="16">
                      
                      <el-select v-model="item.categ"  placeholder="catégorie" size="large"  class="selectCateg">
                        <el-option v-for="categ in state.categ" :key="categ.id" :label="`${categ.label }`" :value="categ.id" ></el-option>
                      </el-select>

                    </el-col>
                  </el-row>
                  <el-row :gutter="10" class="mt-1" style="width: 100%">
                    <el-col :span="2"><b>Question :</b></el-col>
                    <el-col :span="16">
                      <el-input v-model="item.question" style="width: 80%" autosize type="textarea" placeholder="Votre question" />  
                    </el-col>
                  </el-row>
                  <el-row :gutter="10" class="mt-1" style="width: 100%">
                    <el-col :span="2"><b>Code :</b></el-col>
                    <el-col :span="16">
                      <el-input v-model="item.code" style="width: 80%" autosize type="textarea" placeholder="Saisir code spécifique" />  
                    </el-col>
                  </el-row>
                  <el-row :gutter="10" class="mt-1" style="width: 100%">
                    <el-col :span="2"><b>Réponses :</b></el-col>
                    <el-col :span="16">
                      <ul>
                        <li v-for="rep in item.reponses" :key="rep.id" class="mt-2">
                          <el-row :gutter="10" class="mt-1" style="width: 100%">
                            <el-col :span="20">
                              <el-input v-model="rep.label" style="width: 100%" autosize type="text" placeholder="Réponse" />
                            </el-col>
                            <el-col :span="4">
                              <el-checkbox v-model="rep.xValid" label="Valide" size="small"/>
                            </el-col>
                          </el-row>
                          
                        </li>
                      </ul>
                      <el-button class="ms-10 btn btn-secondary btn-sm" @click="insertReponse(item.reponses)">Ajouter une réponse</el-button>
                    </el-col>
                  </el-row>
        
            </el-collapse-item>

            
          </el-collapse>

          <hr>

          <el-button class="ms-10 btn btn-secondary btn-sm" @click="insertQuestion()">Ajouter une Question</el-button>

        </div>
      </el-drawer>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchAndFilter from "@/views/still/search_and_filters_old.vue";
import { ElMessageBox } from 'element-plus'

import { ElNotification } from 'element-plus'

import * as moment from "moment";

import mAxiosApi from "@/api";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
   Datatable,
   SearchAndFilter,
  },

  setup() {
    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      myListLiv: [],
      myQuiz: [] as any,
      categ: [] as any,
      lSN: [],
      mObj: {},
      zUser: {},
      lientest: 0,
      addQUestion : 0,
    });
    const tableHeader = ref([
      { name: "Test", key: "tst_libelle", sortable: false,},
      { name: "", key: "tst_seq", sortable: false,},
    ]);

    const tableFilters = ref([]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang + " HH:mm:ss");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const gestTst = async (idTst) => {

      state.lientest = idTst;

      let reponse = await getAxios("/getQuiz/" + idTst);
      state.myQuiz = reponse.results;

      const myCategs = await getAxios("/getCateg/" + idTst);
      state.categ = myCategs.results;

      state.drawer = true;
    }

    const ExcelFormat = ref({});

    onMounted(async () => { 

      loadTests();
      state.loaderEnabled = false;

    });


    const loadTests= async ()=>{
      let reponse = await getAxios("/getTests");
      state.myList = reponse.results;
      state.initialMyList = reponse.results;
    }

    const handleClose = (done: () => void) => {
      ElMessageBox.confirm('Are you sure you want to close this?')
        .then(() => {
          done()
        })
        .catch(() => {
          // catch error
        })
    }


    
    const insertReponse = (idQuest) => {
      idQuest.push({
        "id": "-1",
        "label": "",
        "chxUser": false,
        "xValid": false
      })
    }


    const insertQuestion = () => {
      state.addQUestion-- ;
      state.myQuiz.push({
        "id": state.addQUestion,
        "categ": "0",
        "display": 0,
        "valid": 0,
        "question": "",
        "code": "",
        "langcode": "",
        "lientest": state.lientest,
        "reponses": [
          {
            "id": "-1",
            "label": "",
            "chxUser": false,
            "xValid": false
          }
        ]
      })
    }
    

    const updateTest = () => {
      const form: any = state.myQuiz;
      mAxiosApi
        .post("setUpdateTest", { form })
        .then(async (data) => {

          ElNotification({
            title: 'Succès',
            message: 'Modifications réalisée avec succès !',
            type: 'success',
          })
          await loadTests();
          state.drawer = false
          
        })
        .catch((err) => {
          console.error(err);
        });
    }

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      ExcelFormat,
      gestTst,
      handleClose,
      insertReponse,
      insertQuestion,
      updateTest

    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>

<style>
  .search-link{
    cursor: pointer;
  }
</style>